import { NavLink } from "react-router-dom";
import { useState, useEffect } from "react";

import { useDispatchCart } from "../controllers/Cart";

import { useLanguage } from "../controllers/Language";

import Quantity from "./Quantity";
// import Rating from './Rating'

export default function ProductCard({ product, productInCart, userLoggedIn, type }) {
  const dispatch = useDispatchCart();

  const Language = useLanguage();

  const [quantity, setQuantity] = useState(1);
  const [totalPrice, setTotalPrice] = useState(0);

  const handleQuantityChange = (direction) => {
    if (direction === "minus") {
      setQuantity((prevQuantity) => prevQuantity - 1);
    } else if (direction === "plus") {
      setQuantity((prevQuantity) => prevQuantity + 1);
    }
  };

  let brutto = 0;
  let netto = 0;

  if (product.category === "Personal Care" || product.id === 139) {
    switch (type) {
      case "partner30":
        brutto = product.brutto_a;
        netto = product.netto_a;
        break;
      case "rdz":
        brutto = product.brutto_b;
        netto = product.netto_b;
        break;
      case "rdz3":
        brutto = product.brutto_e;
        netto = product.netto_e;
        break;
      case "client":
        brutto = product.brutto_c;
        netto = product.netto_c;
        break;
      default:
        brutto = product.brutto;
        netto = product.netto;
        break;
    }
  } else {
    switch (type) {
      case "partner30":
        brutto = product.brutto_a;
        netto = product.netto_a;
        break;
      case "rdz2":
        brutto = product.brutto_b;
        netto = product.netto_b;
        break;
      case "rdz":
        brutto = product.brutto_d;
        netto = product.netto_d;
        break;
      case "rdz3":
        brutto = product.brutto_e;
        netto = product.netto_e;
        break;
      default:
        brutto = product.brutto;
        netto = product.netto;
        break;
    }
  }

  useEffect(() => {
    setTotalPrice(parseFloat(brutto) * quantity);
  }, [brutto, quantity]);

  const cartPayload = {
    productId: product.id,
    name: product.name,
    short_name: product.short_name,
    brutto: brutto,
    netto: netto,
    vat: product.vat,
    ref_number: product.ref_number,
    quantity: quantity,
    weight: product.weight,
  };

  const addToCart = (item) => {
    dispatch({ type: "ADD", item });
  };

  return (
    <>
      {userLoggedIn ? (
        <>
          {!product.brutto ? (
            <h3>...</h3>
          ) : (
            <div className="list__item">
              <NavLink to={`/produkt/${product.id}`}>
                <div
                  className="list__thumbnail"
                  style={{ backgroundImage: `url('${product.main_img}')` }}
                ></div>
              </NavLink>
              <div className="list__item__info">
                <div className="flex-between">
                  <NavLink to={`/produkt/${product.id}`}>
                    <h3>{product.name}</h3>
                  </NavLink>
                </div>
                <p className="product__description">{product.description}</p>
                <p>{product.category}</p>
              </div>
              <div className="product__btn">
                <div className="list__qty__price">
                  <Quantity handleQuantityChange={handleQuantityChange}>{quantity}</Quantity>
                  {!product.brutto ? <h3>...</h3> : <h3 className={`list__product__price ${Language}`}>{totalPrice}</h3>}
                </div>
                {!productInCart ? (
                  <button
                    onClick={() => addToCart(cartPayload)}
                    className="btn-alert w100"
                  >
                    Dodaj do Koszyka
                  </button>
                ) : (
                  <NavLink
                    to={`/koszyk`}
                    className="w100"
                  >
                    <button className="btn-alert w100">Zobacz koszyk</button>
                  </NavLink>
                )}
                <NavLink to={`/produkt/${product.id}`}>
                  <button className="btn">Szczegóły</button>
                </NavLink>
                {/* <Rating rating={product.rating}/> */}
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {!product.brutto ? (
            <h3>...</h3>
          ) : (
            <div className="list__item">
              <NavLink to={`/produkt/${product.id}`}>
                <div
                  className="list__thumbnail"
                  style={{ backgroundImage: `url('${product.main_img}')` }}
                ></div>
              </NavLink>
              <div className="list__item__info">
                <div className="flex-between">
                  <NavLink to={`/produkt/${product.id}`}>
                    <h3>{product.name}</h3>
                  </NavLink>
                </div>
                <p className="product__description">{product.description}</p>
                <p>{product.category}</p>
              </div>
              <div className="product__btn">
                <NavLink
                  to={`/login`}
                  className="w100"
                >
                  <button className="btn-alert w100">Zaloguj się aby zobaczyć ceny</button>
                </NavLink>
                <NavLink to={`/produkt/${product.id}`}>
                  <button className="btn">Szczegóły</button>
                </NavLink>
                {/* <Rating rating={product.rating}/> */}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}
