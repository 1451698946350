import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import Axios from "axios";
// import { InpostGeowidget } from "react-inpost-geowidget";

import { useUser } from "../../controllers/UserContext";
import { useAuth } from "../../controllers/useAuth";
import { useCart } from "../../controllers/Cart";
import { useForm } from "react-hook-form";
import { useLanguage } from "../../controllers/Language";
import { useValidateTaxNumber } from "../../hooks/useValidateTaxNumber";
import { placeOrder } from "../../controllers/Order";
// import { useGetDataOpen } from "../../hooks/useFetchData";

import { apiUrl } from "../../Vars";

import BillingForm from "../../components/BillingForm";
import ShippingForm from "../../components/ShippingForm";
import LoginForm from "../../components/LoginForm";
import LoginMonit from "../../components/Layout/LoginMonit";

import { defaultShema, shippingShema, companyShema, shippingCompanyShema } from "../../controllers/ValidationShemas";

export default function Order() {
  const devState = process.env.REACT_APP_DEVELOPER_STATE === "true" ? true : false;

  console.log(true);

  // const [ user, setUser ] = useState()
  const user = useUser();
  const userCart = useCart();

  const userLoggedIn = useAuth();

  const Language = useLanguage();

  const [isCompany, setIsCompany] = useState(true);
  const [differentShipping, setDifferentShipping] = useState(false);
  const [shippingMethod] = useState("delivery");
  const [shippingCost, setShippingCost] = useState(22);
  const [orderValue, setOrderValue] = useState(0);
  const [productsValue, setProductsValue] = useState(0);
  const [combinedShema, setCombinedShema] = useState(defaultShema);
  const [loginUser, setLoginUser] = useState(false);
  const [userData, setUserData] = useState({});
  const [point] = useState(null);
  const [userCountry, setUserCountry] = useState("PL");
  const [taxNumber, setTaxNumber] = useState("");
  const [idle, setIdle] = useState(false);
  const [referralUserParam, setReferralUserParam] = useState();
  const [taxErrorMsg, setTaxErrorMsg] = useState();
  const [payment_method, setPayment_method] = useState("payU");

  const { taxNumberIsValid, validateTaxNumber } = useValidateTaxNumber(userCountry, taxNumber, setIdle, setTaxErrorMsg);

  const [searchParams] = useSearchParams();
  const cart_id = searchParams.get("cart_id");

  let discount = 0;

  let discountObject = JSON.parse(sessionStorage.getItem("discount"));
  if (discountObject) {
    discount = parseInt(discountObject.value) / 100;
  } else {
    discountObject = {
      name: "none",
      value: 0,
    };
  }

  const handleLoggedInUser = () => {
    devState && console.log("user.auth");
    devState && console.log(user.auth);
  };

  useEffect(() => {
    if (isCompany && differentShipping) {
      setCombinedShema(shippingCompanyShema);
    } else if (isCompany) {
      setCombinedShema(companyShema);
    } else if (differentShipping) {
      setCombinedShema(shippingShema);
    } else {
      setCombinedShema(defaultShema);
    }
  }, [isCompany, differentShipping]);

  useEffect(() => {
    if (user.length === 0) {
      setUserCountry("PL");
    } else if (user.user.billingAddress === undefined) {
      setUserCountry("PL");
    } else {
      const country = user.user.billingAddress.country;
      setUserCountry(country);
    }
  }, [user]);

  const countryChange = (value) => {
    setUserCountry(value);
  };

  useEffect(() => {
    if (userCountry) {
      switch (true) {
        case userCountry === "PL" && payment_method === "transfer":
          setShippingCost(22);
          break;
        case userCountry === "PL" && payment_method === "on_delivery":
          setShippingCost(25);
          break;
        case userCountry === "GB" && payment_method === "transfer":
          setShippingCost(145);
          break;
        case userCountry !== "PL" && userCountry !== "GB" && payment_method === "transfer":
          setShippingCost(145);
          break;
        case userCountry !== "PL" && userCountry !== "GB" && payment_method === "payU":
          setShippingCost(145);
          break;
        default:
          setShippingCost(22);
          break;
      }
    }
  }, [userCountry, payment_method]);

  const { register, handleSubmit, formState, reset } = useForm({
    resolver: zodResolver(combinedShema),
  });

  const { errors } = formState;

  useEffect(() => {
    if (user.auth && user.user) {
      const d = user.user.shippingAddress;
      const data = {
        ...user.user.billingAddress,
        email: user.user.email,
        repeatEmail: user.user.email,
        shippingName: d.name,
        shippingSurname: d.surname,
        shippingCompanyName: d.companyName,
        shippingStreet: d.street,
        shippingAddressNumber1: d.addressNumber1,
        shippingAddressNumber2: d.addressNumber2,
        shippingZipCode: d.zipCode,
        shippingCity: d.city,
        shippingCountry: d.country,
        shippingPhone: d.phone,
        data_processing: user.user.flags.consent === "true" ? true : false,
        terms: user.user.flags.consent === "true" ? true : false,
      };
      setUserData(data);
      setUserCountry(user.user.billingAddress.country);
      setTaxNumber(user.user.billingAddress.taxNumber);
      Axios.get(`${apiUrl}/api/user-is-ref/${user.user.email}`)
        .then((res) => {
          setReferralUserParam(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    reset({ ...userData });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  useEffect(() => {
    let productsCost = 0;
    if (userCart) {
      userCart.map((item) => {
        let price = 0;
        if (userCountry !== "PL") {
          price = parseFloat(item.netto);
        } else {
          price = parseFloat(item.brutto);
        }
        return (productsCost = productsCost + (price - price * discount) * item.quantity);
      });
    }

    setProductsValue(Math.round(productsCost * 100) / 100);
  }, [userCart, discount, userCountry]);

  useEffect(() => {
    setOrderValue(productsValue + shippingCost);
  }, [shippingCost, productsValue]);

  const companyToggle = (e) => {
    setIsCompany((prevIsCompany) => !prevIsCompany);
  };

  const shippingToggle = (e) => {
    setDifferentShipping((prevDifferentShipping) => !prevDifferentShipping);
  };

  const loginForm = (e) => {
    setLoginUser((prevLoginUser) => !prevLoginUser);
  };

  const submitOrder = (data) => {
    const orderObject = {
      user: user,
      isCompany: isCompany,
      differentShipping: differentShipping,
      shippingMethod: shippingMethod,
      shippingCost: shippingCost,
      payment_method: payment_method,
      orderValue: orderValue,
      userCart: userCart,
      point: point,
      userCountry: userCountry,
      taxNumber: taxNumber,
      referralUserParam: referralUserParam,
      cart_id: cart_id,
      discountObject: discountObject,
      setIdle: setIdle,
    };

    placeOrder(data, orderObject);
  };

  const showError = (errors) => {
    console.log(errors);
  };

  devState && console.log("userCountry, taxNumber");
  devState && console.log(userCountry, taxNumber);
  devState && console.log("user");
  devState && console.log(user);

  return (
    <>
      {!userLoggedIn ? (
        <LoginMonit location={"zamowienie"} />
      ) : (
        <div className="order">
          <h2 className="title">Zamówienie</h2>
          <p className="undertitle">
            Prosimy o dokładne i poprawne wypełnienie formularza zamówienia oraz o wybór sposobu dostawy i metody płatności. Jeśli chcesz, aby towar został wysłany na inny adres niż dane rozliczeniowe, to zaznacz odpowiedni punkt i podaj dane do wysyłki. Jeśli wybierzesz formę płatności PayU lub
            Blik, to po kliknięciu przycisku "Zamawiam i płacę" nastąpi przekierowanie na stronę operatora płatności.
          </p>
          <br />
          <br />
          <div className="order__table">
            {/* left side --------------------------------------------------------------------------------- */}
            <div className="order__left">
              {user.length === 0 || !user.auth ? (
                <>
                  <div className="input__line__checkbox">
                    <input
                      type="checkbox"
                      name="login"
                      id="login"
                      value={loginUser}
                      onChange={loginForm}
                    />
                    <label htmlFor="login"> Mam już konto i chcę się zalogować </label>
                  </div>
                  {loginUser ? (
                    <LoginForm
                      location={"zamowienie"}
                      action={handleLoggedInUser}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
              <h3>Dane do zamówienia</h3>
              {user.length === 0 || !user.auth ? (
                <></>
              ) : (
                <>
                  <p>Twój adres emai:</p>
                  <p>
                    {" "}
                    <strong>{user.user.email}</strong>{" "}
                  </p>
                </>
              )}
              <form
                action=""
                id="order_form"
                className="order__form"
                onSubmit={handleSubmit(submitOrder, showError)}
              >
                <BillingForm
                  register={register}
                  errors={errors}
                  companyToggle={companyToggle}
                  isCompany={isCompany}
                  location={"orderPage"}
                  countryChange={countryChange}
                  setTaxNumber={setTaxNumber}
                  taxNumberIsValid={taxNumberIsValid}
                  validateTaxNumber={validateTaxNumber}
                  taxErrorMsg={taxErrorMsg}
                  userCountry={userCountry}
                  taxNumber={taxNumber}
                />
                <br />
                {/* Shipping data ---------------------------------------------------------------------------- */}
                {shippingMethod === "box" ? (
                  <></>
                ) : (
                  <>
                    <div className="input__line__checkbox">
                      <input
                        type="checkbox"
                        name="additional_shipping"
                        id="additional_shipping"
                        value={differentShipping}
                        onChange={shippingToggle}
                      />
                      <label htmlFor="additional_shipping"> Wysyłka na inny adres </label>
                    </div>
                    {differentShipping ? (
                      <ShippingForm
                        register={register}
                        errors={errors}
                        isCompany={isCompany}
                      />
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </form>
            </div>
            {/* right side ------------------------------------------------------------------------------ */}
            <div className="order__right">
              {/* order summary ------------------------------------------------------------ */}
              <div className="summary">
                <h3>Podsumowanie zamówienia</h3>
                <ul className="summary__list">
                  {userCart ? (
                    userCart.map((item) => {
                      let price = 0;
                      if (userCountry !== "PL") {
                        price = parseFloat(item.netto);
                      } else {
                        price = parseFloat(item.brutto);
                      }
                      return (
                        <li
                          key={item.productId}
                          className="detail__item"
                        >
                          <span>
                            <strong>{item.quantity}</strong> x {item.name}
                          </span>
                          <span className={`order__price ${Language}`}>{((Math.round((price - price * discount) * 100) / 100) * item.quantity).toFixed(2)}</span>
                        </li>
                      );
                    })
                  ) : (
                    <div></div>
                  )}
                  <li className="detail__item">
                    <span>
                      {" "}
                      <span>Łączny koszt produktów</span>{" "}
                    </span>
                    <span className={`order__price ${Language}`}> {productsValue.toFixed(2)} </span>
                  </li>
                  <li className="detail__item">
                    <span>
                      {" "}
                      <span>Koszt dostawy</span>{" "}
                    </span>
                    <span className={`order__price ${Language}`}> {shippingCost} </span>
                  </li>
                  <li className="detail__item sum">
                    <span>
                      {" "}
                      <span>Łącznie do zapłaty</span>{" "}
                    </span>
                    <span className={`order__price ${Language}`}> {(orderValue ? orderValue : 0).toFixed(2)} </span>
                  </li>
                </ul>
              </div>
              {/* payment methods ---------------------------------------------------------- */}
              <div className="payment">
                <h3>Płatności obsługuje PayU</h3>
                <ul className="shipping__items">
                  {(user && user.user.userType === "partner30") || (user && user.user.userType.includes("rdz")) ? (
                    <li className="detail__item">
                      <span>
                        <input
                          type="radio"
                          id="transfer"
                          name="payment_method"
                          value="transfer"
                          {...register("payment_method")}
                          onChange={() => setPayment_method("transfer")}
                        />
                        <span>
                          {" "}
                          <label htmlFor="transfer">Przelew tradycyjny</label>{" "}
                        </span>
                      </span>
                    </li>
                  ) : (
                    <></>
                  )}
                  <li className="detail__item">
                    <span>
                      <input
                        type="radio"
                        id="payu"
                        name="payment_method"
                        value="payU"
                        {...register("payment_method")}
                        onChange={() => setPayment_method("payU")}
                      />
                      <span>
                        {" "}
                        <label htmlFor="payu">Płacę z PayU</label>{" "}
                      </span>
                    </span>
                  </li>
                  {orderValue < 4975 && userCountry === "PL" && (
                    <li className="detail__item">
                      <span>
                        <input
                          type="radio"
                          id="on_delivery"
                          name="payment_method"
                          value="on_delivery"
                          {...register("payment_method")}
                          onChange={() => setPayment_method("on_delivery")}
                        />
                        <span>
                          {" "}
                          <label htmlFor="on_delivery">Płatność za pobraniem</label>{" "}
                        </span>
                      </span>
                    </li>
                  )}
                  <span className="input__err error_payment">{errors.payment_method?.message && "Proszę wybrać formę płatności"}</span>
                </ul>
                <div className="payments__img">
                  <img
                    src=".\assets\payu_logo_lime.png"
                    alt="payu"
                  />
                  <img
                    src=".\assets\blik_logo_rgb.png"
                    alt="blik"
                  />
                </div>
                {/* place order ----------------------------------------------------- */}
                {loginUser || !user.length === 0 || user.auth ? (
                  <></>
                ) : (
                  <>
                    <div className="input__line__checkbox">
                      <input
                        type="checkbox"
                        id="account-create"
                      />
                      <label htmlFor="account-create"> Chcę założyć konto w trakcie składania zamówienia. </label>
                    </div>
                  </>
                )}
                {user && user.user.flags.consent === "true" ? (
                  <p>Składając zamówienie akceptuję regulamin zakupów, konieczne do złożenia zamówienia oraz wyrażam zgodę na przetwarzanie podanych przeze mnie danych osobowych przez Sklep Gen Factor w celu realizacji zamówienia.</p>
                ) : (
                  <>
                    <div className="input__line__checkbox">
                      <input
                        type="checkbox"
                        id="terms"
                        {...register("terms")}
                      />
                      <label htmlFor="terms"> Akceptuję regulamin zakupów (konieczne do złożenia zamówienia) </label>
                    </div>
                    <div className="input__line__checkbox">
                      <span className="input__err error_select">{errors.terms?.message && "Pole jest wymagane"}</span>
                    </div>
                    <div className="input__line__checkbox">
                      <input
                        type="checkbox"
                        id="data_processing"
                        {...register("data_processing")}
                      />
                      <label htmlFor="data_processing">Wyrażam zgodę na przetwarzanie podanych przeze mnie danych osobowych przez Sklep Gen Factor w celu realizacji zamówienia.</label>
                    </div>
                    <div className="input__line__checkbox">
                      <span className="input__err error_select">{errors.data_processing?.message && "Pole jest wymagane"}</span>
                    </div>
                  </>
                )}
                {/* <>
                  <div className="input__line__checkbox">
                    <input
                      type="checkbox"
                      id="terms"
                      {...register("terms")}
                    />
                    <label htmlFor="terms"> Akceptuję regulamin zakupów (konieczne do złożenia zamówienia) </label>
                  </div>
                  <div className="input__line__checkbox">
                    <span className="input__err error_select">{errors.terms?.message && "Pole jest wymagane"}</span>
                  </div>
                  <div className="input__line__checkbox">
                    <input
                      type="checkbox"
                      id="data_processing"
                      {...register("data_processing")}
                    />
                    <label htmlFor="data_processing">Wyrażam zgodę na przetwarzanie podanych przeze mnie danych osobowych przez Sklep Gen Factor w celu realizacji zamówienia.</label>
                  </div>
                  <div className="input__line__checkbox">
                    <span className="input__err error_select">{errors.data_processing?.message && "Pole jest wymagane"}</span>
                  </div>
                </> */}
                {idle ? (
                  <button
                    type="submit"
                    form="order_form"
                    className="btn idle order__btn"
                    disabled={idle}
                  >
                    {" "}
                    Prozę czekać...{" "}
                  </button>
                ) : (
                  <button
                    type="submit"
                    form="order_form"
                    className="btn order__btn"
                  >
                    {" "}
                    Zamawiam i płacę{" "}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
